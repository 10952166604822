<template>
  <div class="container-fluid py-4 add-student ">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">عرض التقرير المالى للحفلة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
                 <!-- promocode -->
      <!-- lectures -->
      <div class="row codes-row">
        <div class="col-12">
          <div class="card my-4">
            <!-- <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">الكيو ار كود المستخدم</h6>
              </div>
            </div> -->
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0" style="border-collapse: unset">
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-uppercase font-weight-bolder">رقم الحفلة</th>
                     <th class="text-uppercase font-weight-bolder text-center" colspan="3"> العملاء</th>
                     <th class="text-uppercase font-weight-bolder text-center" colspan="3"> المرافقين</th>
                     <th class="text-uppercase font-weight-bolder text-center" colspan="3"> الإجمالي للحفلة</th>
                    </tr>
                    <tr>
                   <th class="text-uppercase font-weight-bolder">
                          المسدد
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          المتبقى
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          الإجمالى
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          المسدد
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          المتبقى
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          الإجمالى
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          المسدد
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          المتبقى
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                          الإجمالي الكلى
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p class="text-s mb-0">{{ eventReport.event_id }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ eventReport.total_paid_from_clients}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ eventReport.remaining_amount_from_clients }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ eventReport.total_amount_from_clients}}</p>
                      </td>

                      <td>
                        <p class="text-s mb-0">{{ eventReport.total_paid_from_companions}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ eventReport.remaining_amount_from_companions}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ eventReport.total_amount_from_companions}} </p>
                      </td>

                      <td>
                        <p class="text-s mb-0">
                          {{ eventReport.total_paid }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ eventReport.total_remaining_amount }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ eventReport.total_amount }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
               <div class="row text-center d-flex justify-content-center mr-t">
                 <div class="text-center col-lg-6">
                  <router-link to="/events">
                  <vmd-button
                    class="my-4 mb-2"
                    type='reset'
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                  </router-link>
                </div>
                       </div>
            </form>
          </div>
        </div>
        <!-- tabs -->
      </div>
    </div>
    <!-- start course receipts modal-->
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'ShowStudent',
  components: {
    VmdButton
  },
  data () {
    return {
      event: {},
      eventReport: {}
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}events/${this.$route.params.id}`)
      .then((response) => {
        this.event = response.data.data
      })
    this.$http
      .get(`${this.$hostUrl}events/${this.$route.params.id}/event-report`)
      .then((response) => {
        this.eventReport = response.data.report
      })
  }
}

</script>
<style>
.margin0{
  margin-top:0 !important
}
.tab-div{
  margin-top: 50px !important;
}
.nav-tabs{
  border-bottom:unset !important
}
.tab-div li{
  width:33%
}
.tab-div .btn-info{
  color:#fff
}
.nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link{
  color:#fff !important;
  background-image: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
 }
.nav-tabs .nav-link{
  color:#222 !important;
  background:#f8f9fa !important
}
.watches-time{
  font-weight: bold;
  font-size: 13px !important;
}
.paid-back{
 background: #baf2c5a1 !important;
}
.refund-back{
  background: #fb060669 !important;
}
.student-info table{
  width:75%;
  text-align: right;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.student-info td{
  background: #fff !important;
}
.close-btn{
  width:14%;
  margin-top:30px !important
}
#ch-receipt{
  padding-top: 20px;
  padding-right: 20px;
}
.student-enroll-c td{
  white-space: normal;
}
.w-96{
 width: 96% !important
}
.course-payment .modal-content{
  max-height: 700px !important;
}
.mr-t{
  margin: 60px auto 10px auto;
}
.codes-row{
  margin: 40px 0px;
  text-align: right !important;
}
</style>
